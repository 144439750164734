/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 23, 2023 */



@font-face {
    font-family: 'hello_denver_display_boldbold';
    src: url('Fonts/hellodenverdisplaybold_v1.6-webfont.woff2') format('woff2'),
         url('Fonts/hellodenverdisplaybold_v1.6-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'hello_denver_display_boldbold';
    src: url('Fonts/hellodenverdisplaybold_v1.6-webfont.woff2') format('woff2'),
         url('Fonts/hellodenverdisplaybold_v1.6-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'hello_denver_display_regulaRg';
    src: url('Fonts/hellodenverdisplayregular_v1.6-webfont.woff2') format('woff2'),
         url('Fonts/hellodenverdisplayregular_v1.6-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'hello_denver_display_regulaRg';
    src: url('Fonts/hellodenverdisplayregular_v1.6-webfont.woff2') format('woff2'),
         url('Fonts/hellodenverdisplayregular_v1.6-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'viburmedium';
    src: url('Fonts/vibur-regular-webfont.woff2') format('woff2'),
    url('Fonts/vibur-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}